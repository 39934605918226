.fixed-sticky-wrapper {
  opacity: 0;
  position: fixed;
  top: 1.5rem;
  max-width: 1400px;
  height: auto;
  z-index: 1;
  width: fit-content;
  border-radius: 17px;
  transition: all 150ms ease-in-out;
  // overflow: hidden;
 
  .btn-wrapper {
    // background: linear-gradient(to right, red, rgba(256,256,256,.9) 137px);
    background: rgba(darken(#fafafa, 0.5%),.9);

    backdrop-filter: blur(10px);
    box-shadow: 0 0 30px rgba(black, 0.2);
    border-radius: 17px;
    &.shadow {
      position: relative;
      flex-wrap: nowrap;
      display: flex;
      align-items: center;

      width: fit-content;
      max-width: 100%;
      .all-button-container {
        width: auto;
        // position: absolute;
        // left: 0;
        // top: 0;
        // bottom: 0;
        // background: linear-gradient(to left, rgba(256,256,256,0), rgba(256,256,256,.8) .5rem);
        // backdrop-filter: blur(1px);
        padding: 0.75rem;
        border-radius: 17px;
        .all-btn {
          background-color: rgba(#282d23, 0.05);
          transition: none;
          position: relative;
          span {
            color: lighten(#282d23, 25%);
            text-wrap: nowrap;
            opacity: 0;
          }
          .svg-wrapper {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 22px;
            width: 22px;
          }
          &.active {
            background-color: lighten(#282d23, 5%);
            span {
              opacity: 100;
              color: white;
            }
            .svg-wrapper {
              display: none;
            }
          }
        }
        .blur-left {
          z-index: 9;
          flex-basis: 0.75rem;
          align-self: stretch;
          background: transparent;
          background-image: linear-gradient(
            to right,
            rgba(250, 250, 250, 1),
            rgba(250, 250, 250, 0)
          );
        }
        &.container-active {
          background: white;
        }
      }
      .plus-sign {
        color: lighten(#68755b, 20%);
        min-height: max-content;
        display: inline-flex;
        font-weight: 400;
        font-size: 1.25rem;
      }
    }
    .buttons-wrapper {
      // margin-left: 0.75rem;
      padding: 0.75rem;
      // padding-left: calc(136px - 0.75rem);
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      overflow: auto;
      white-space: nowrap;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      gap: 0.75rem 0.75rem;
      border-radius: 17px;
      &.container-active {
        background: white;
      }
    }
    .tag-btn {
      padding: 0.5rem 1rem;
      border-radius: 10px;
      // border: 1px solid #ccc;
      // background-color: #fafafa;
      border: none;
      background-color: rgba(#282d23, 0.05);

      color: lighten(#282d23, 25%);
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 100ms ease-in-out;
      &.active {
        background-color: lighten(#282d23, 15%) !important;
        color: #fafafa;
        .close-icon {
          background: url("/public/icon_close.svg") no-repeat center center;
          height: 20px;
          width: 20px;
          display: block;
        }
      }
    }
  }
  .blur-right {
    z-index: 9;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2rem;
    border-radius: 0 17px 17px 0;
    background-image: linear-gradient(
      to right,
      rgba(250, 250, 250, 0) 50%,
      rgba(256, 256, 256, .9)
    );
  }
}

.show {
  transition: opacity 100ms ease-in-out;
  opacity: 1 !important;
}

.hide {
  transition: opacity 25ms ease-in-out;
  opacity: 0 !important;
}

.close {
  background: url("/icon_close.svg") no-repeat center center;
  height: 20px;
  width: 20px;
  display: block;
}

@media screen and (max-width: 1700px) {
  .fixed-sticky-wrapper {
    width: auto;
    // max-width: calc(90vw + 30px);
    max-width: 1024px;
    height: auto;
    .btn-wrapper {
      justify-content: flex-start;
      width: 100%;
      overflow: auto;
      white-space: nowrap;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .fixed-sticky-wrapper {
    max-width: 700px;
  }
}

@media screen and (max-width: 750px) {
  .fixed-sticky-wrapper {
    top: unset;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    .btn-wrapper {
      justify-content: flex-start;
    }
  }
}

@media (hover: hover) {
  /* when hover is supported */
  // .fixed-sticky-wrapper {
  //   .btn-wrapper {
      .tag-btn {
        &:hover {
          background-color: rgba(#282d23, 0.15) !important;
        }
      }
  //   }
  // }
}

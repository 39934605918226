.home-page-root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.home-page-wrapper {
  height: 100%;
  max-width: 1400px;
}

@media screen and (max-width: 1700px) {
  .home-page-root {
    padding: 0 5vw;
  }
}

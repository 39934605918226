
html {
  background-color: #eeeeee
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */